<template>
    <modal name="InfoPayModal" width="308px" height="420px">
        <div class="modal--view">
            <div class="modal--head">
                <a @click="exit"><span class="icon-close"></span></a>
            </div>
            <div class="modal--body">
                <div class="text--container">
                    <p>Вы сейчас используете мобильное приложение SOVA. Оплатить тариф можно через личный кабинет браузерной версии сервиса</p>
                </div>
                <div class="title--container">
                    <p>https://app.esova.ru/auth</p>
                </div>
                <div class="image--container">
                    <img src="../../assets/img/pay.png" class="pay__img">
                </div>
                <div class="button--container">
                    <button @click="exit">ОК</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    export default {
        name: "InfoPayModal",
        methods: {
            exit(){
                this.$modal.hide('InfoPayModal')
            }

        }
    }
</script>

<style scoped lang="scss">
    .modal--view{
        width: 308px;
        height: 420px;
        background: #FFFFFF;
        border: 0 solid #F2F2F2;
        box-shadow: 0 2px 7px 1px rgba(88,88,88,0.40);
        border-radius: 4px;
        display: flex;
        flex-direction: column;

        .modal--head{
            display: flex;
            justify-content: flex-end;
            margin-top: 16px;
            .icon-close{
                margin-right: 16px;
                cursor: pointer;
                color: rgb(161,160,161);
                font-size: 10px;
            }


            a{
                &:hover{
                    filter: brightness(87.5%);
                }
            }
        }

        .modal--body{
            width: 100%;
            .title--container{
                margin-top: 4px;
                width: 100%;
                p{
                    font-family: Roboto, "sans-serif";
                    font-weight: 800;
                    font-size: 19px;
                    color: #E55800;
                    width: 100%;
                    text-align: center;
                    line-height: 23px;
                }
            }

            .text--container{
                margin-top: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                p{
                    font-family: Roboto, "sans-serif";
                    font-weight: 400;
                    font-size: 14px;
                    color: #636363;
                    line-height: 18px;
                    width: 272px;
                }
            }

            .button--container{
                margin-top: 11px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                button{
                    font-family: Roboto, "sans-serif";
                    font-weight: 400;
                    font-size: 12px;
                    color: #CA4EBB;
                    letter-spacing: 2px;
                    text-align: center;
                    cursor: pointer;
                    border: 0;
                    outline: 0;
                    padding-left: 21px;
                    padding-right: 21px;
                    height: 32px;
                    border-radius: 8px;
                    margin-right: 2px;
                    background: transparent;
                    &:hover{
                        background-color: #F1F1F1;
                    }
                }
            }
        }
    }


    .image--container{
        margin-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            width: 103px;
            height: 207px;
        }
    }
</style>
